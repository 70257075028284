import React, { useEffect, useState } from 'react'
import Gabbung from '../../assets/hero-animation/gabung.png'
import Logo from '../../assets/logo.png'
import Charity from '../../assets/charity-fund.png'
import Marketing from '../../assets/marketing.png'
import LottieImage from '../lottieimage/LottieImage'
import Logo01 from '../../assets/newlogo/logo01.png'
import Logo02 from '../../assets/newlogo/logo02.png'
import logotext from '../../assets/logo-text.png'
import Screenshot01 from '../../assets/screenshot-01.jpg'
import Screenshot02 from '../../assets/screenshot-02.jpg'

import TamilData from '../tamilcharity/TamilData'
import MainPlay from '../playlist/MainPlay'

import { getServerSideProps } from '../../components/playlist/PlayList'


const StarParalax = () => {

    const [playList, setPlayList] = useState([])

    useEffect(async () => {
        let data = await getServerSideProps();
        console.log(data)
        setPlayList(data.props.data.items)
    }, [])

    return (
        <div className="starparalax-v2">
            {/* <div class="background">
                <img src={backgroundimg} alt="" />
            </div> */}
            <div className="hero-main-content">
                <div className="hero-sub">
                    <div className="container">
                        <div className="hero-header">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="hero-header-text">
                                        {/* <h1>SAFEPIZZA is now</h1> */}
                                        <img src={logotext} alt="" className="img-responsive" />
                                        {/* <h1>STORE COMING SOON JAN 2022</h1>
                                        <h2>CREATE, BUY, BID, SELL OR TRADE NFTS IN BINANCE SMART CHAIN</h2> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="container charity-h3">
                <h3>How to View Your Rewards In BNB</h3>
                <h4>NOTE:</h4>
                <h3>BNB Rewards Are Based on Reflections </h3>
                <h3>BNB rewards are based on Buy and Sell Transaction.  The more transaction, the Rewards are Que Up To Distributed
                    Smart Contract will execute Distributions Hourly and depends on the Transaction
                </h3>
                <h3>The More You Hold PIZZA NFT $PIZZA tokens, the more you earn.</h3>
                <div className="rewards-ol">
                    <ol>
                        <li>Go to this LINK <a href="https://bscscan.com/token/0xb07905396a419b121213efe1d17cfd0ff20ae597#readContract">https://bscscan.com/token/0xb07905396a419b121213efe1d17cfd0ff20ae597#readContract</a></li>
                        <li>Go to NUMBER #18 AND #19</li>
                        <li>PASTE YOUR PIZZA NFT WALLET ADDRESS HERE
                            <img src={Screenshot01} alt="" className='img-responsive' />
                        </li>
                        <li>THE UNITS will appear in WEI</li>
                        <li>See the Conversion Chart
                            <a href="https://bscscan.com/unitconverter">https://bscscan.com/unitconverter</a>
                            <img src={Screenshot02} alt="" className='img-responsive' />
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    )
}

export default StarParalax
